<template>
  <main>
    <div class="container-fluid">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-card>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </b-card>
        </template>
      
      <div class="row justify-content-center pt-3" v-if="!loading">
        <div class="col-12 text-center pb-1">
          <h1 style="color:var(--accent3-color);">Products</h1>
        </div>
          <div class="col-12">
            <b-card no-body>
              <b-tabs fill>
                <b-tab title="Globelink" active>
                  <div class="row">
                    <div class="col-12">
                      <template>
                        <div class="accordion" role="tablist">
                          <!-- Dynamic Globelink products -->
                          <b-card no-body class="mb-0" v-for="(p,i) in productsCollinson" :key="i">
                            <main>
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + p" variant="success" visible>{{ products[p] }}</b-button>
                              </b-card-header>
                              <b-collapse :id="'accordion-'+p" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <h4 class="text-center mb-1">Documents</h4>
                                    <b-list-group class="listgroupsdesc">
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">TOB</span>
                                          <div v-if="product_documents[p].tob_link">
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-success">{{product_documents[p].tob_title}}</a>
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">IPID</span>
                                          <div v-if="product_documents[p].ipid_link">
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-success">{{product_documents[p].ipid_title}}</a>
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">PWO</span>
                                          <div v-if="product_documents[p].pwo_link">
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-success">{{product_documents[p].pwo_title}}</a>
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-card-text>
                                  <b-card-text>
                                    <h4 class="text-center mb-1 mt-2">Coverages</h4>
                                      <div v-for="(pr,k) in productsArr" :key="k">
                                          <b-list-group class="mb-2 listgroupsdesc" v-for="pp in pr[productsCollinson[i]]" :key="pp.coverage_code">
                                            <b-list-group-item><b-badge variant="primary">Description</b-badge> {{ pp.description_en }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Limit</b-badge> {{ pp.coverage_limit }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Excess</b-badge> {{ pp.coverage_excess }}</b-list-group-item>
                                          </b-list-group>
                                      </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </main>
                          </b-card>
                        </div>
                      </template>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Sompo" active v-if="productsSompo.length">
                  <div class="row">
                    <div class="col-12">
                      <template>
                        <div class="accordion" role="tablist">
                          <!-- Dynamic Sompo products -->
                          <b-card no-body class="mb-0" v-for="(p,i) in productsSompo" :key="i">
                            <main>
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + p" variant="success" visible>{{ products[p] }}</b-button>
                              </b-card-header>
                              <b-collapse :id="'accordion-'+p" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <h4 class="text-center mb-1">Documents</h4>
                                    <b-list-group class="listgroupsdesc">
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">TOB</span>
                                          <div v-if="product_documents[p].tob_link">
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-success">{{product_documents[p].tob_title}}</a>
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">IPID</span>
                                          <div v-if="product_documents[p].ipid_link">
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-success">{{product_documents[p].ipid_title}}</a>
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">PWO</span>
                                          <div v-if="product_documents[p].pwo_link">
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-success">{{product_documents[p].pwo_title}}</a>
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-card-text>
                                  <b-card-text>
                                    <h4 class="text-center mb-1 mt-2">Coverages</h4>
                                      <div v-for="(pr,k) in productsArr" :key="k">
                                          <b-list-group class="mb-2 listgroupsdesc" v-for="pp in pr[productsSompo[i]]" :key="pp.coverage_code">
                                            <b-list-group-item><b-badge variant="primary">Description</b-badge> {{ pp.description_en }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Limit</b-badge> {{ pp.coverage_limit }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Excess</b-badge> {{ pp.coverage_excess }}</b-list-group-item>
                                          </b-list-group>
                                      </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </main>
                          </b-card>
                        </div>
                      </template>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Mondial">
                  <div class="row">
                    <div class="col-12">
                      <template>
                        <div class="accordion" role="tablist">
                          <!-- Dynamic Mondial products -->
                          <b-card no-body class="mb-0" v-for="(p,i) in productsMondial" :key="i">
                            <main>
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + p" variant="success" visible>{{ products[p] }}</b-button>
                              </b-card-header>
                              <b-collapse :id="'accordion-'+p" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <h4 class="text-center mb-1">Documents</h4>
                                    <b-list-group class="listgroupsdesc">
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">TOB</span>
                                          <div v-if="product_documents[p].tob_link">
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-success">{{product_documents[p].tob_title}}</a>
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">IPID</span>
                                          <div v-if="product_documents[p].ipid_link">
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-success">{{product_documents[p].ipid_title}}</a>
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">PWO</span>
                                          <div v-if="product_documents[p].pwo_link">
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-success">{{product_documents[p].pwo_title}}</a>
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-card-text>
                                  <b-card-text>
                                    <h4 class="text-center mb-1 mt-2">Coverages</h4>
                                      <div v-for="(pr,k) in productsArr" :key="k">
                                          <b-list-group class="mb-2 listgroupsdesc" v-for="pp in pr[productsMondial[i]]" :key="pp.coverage_code">
                                            <b-list-group-item><b-badge variant="primary">Description</b-badge> {{ pp.coverage_code }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Limit</b-badge> {{ pp.coverage_limit }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Excess</b-badge> {{ pp.coverage_excess }}</b-list-group-item>
                                          </b-list-group>
                                      </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </main>
                          </b-card>
                        </div>
                      </template>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Battleface">
                    <div class="row">
                      <div class="col-12">
                        <template>
                        <div class="accordion" role="tablist">
                          <!-- Dynamic Battleface products -->
                          <b-card no-body class="mb-0" v-for="(p,i) in productsBattleface" :key="i">
                            <main>
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + p" variant="success" visible>{{ products[p] }}</b-button>
                              </b-card-header>
                              <b-collapse :id="'accordion-'+p" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <h4 class="text-center mb-1">Documents</h4>
                                    <b-list-group class="listgroupsdesc">
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">TOB</span>
                                          <div v-if="product_documents[p].tob_link">
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-success">{{product_documents[p].tob_title}}</a>
                                            <a target="_blank" :href="product_documents[p].tob_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">IPID</span>
                                          <div v-if="product_documents[p].ipid_link">
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-success">{{product_documents[p].ipid_title}}</a>
                                            <a target="_blank" :href="product_documents[p].ipid_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                      <b-list-group-item>
                                        <div class="flexDiv">
                                          <span class="font-weight-bold">PWO</span>
                                          <div v-if="product_documents[p].pwo_link">
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-success">{{product_documents[p].pwo_title}}</a>
                                            <a target="_blank" :href="product_documents[p].pwo_link" class="btn btn-sm btn-outline-danger ml-2">Download</a>
                                          </div>
                                        </div>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-card-text>
                                  <b-card-text>
                                    <h4 class="text-center mb-1 mt-2">Coverages</h4>
                                      <div v-for="(pr,k) in productsArr" :key="k">
                                          <b-list-group class="mb-2 listgroupsdesc" v-for="pp in pr[productsBattleface[i]]" :key="pp.coverage_code">
                                            <b-list-group-item><b-badge variant="primary">Description</b-badge> {{ pp.description_en }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Limit</b-badge> {{ pp.coverage_limit }}</b-list-group-item>
                                            <b-list-group-item><b-badge variant="primary">Excess</b-badge> {{ pp.coverage_excess }}</b-list-group-item>
                                          </b-list-group>
                                      </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </main>
                          </b-card>
                        </div>
                      </template>
                      </div>
                    </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
      </div>
    </b-skeleton-wrapper>
    </div>
  </main>
</template>

<script>
/* eslint-disable global-require */
// import Locale from '../components/Locale.vue'
import {
  BRow, BCol, BLink, BListGroup, BListGroupItem, BButton, BTabs, BTab, BCard, BCardBody, BCardHeader, BCardText, BCollapse, VBToggle, BBadge, BSkeleton, BSkeletonWrapper
} from 'bootstrap-vue'
import { products, product_documents } from '@/products';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BListGroup,
    BListGroupItem,
    BButton,
    BTabs,
    BTab,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCollapse,
    BBadge,
    BSkeleton,
    BSkeletonWrapper
    // Locale
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      products,
      product_documents,
      productsArr: [],
      loading:true,
      productsCollinson: ['product-single-900','product-single-901','product-single-902','product-single-903','product-single-904','product-single-908','product-single-909','product-annual-901','product-annual-902','product-longstay-901','product-longstay-902'],
      // productsSompo: ['product-single-300','product-single-301','product-single-302','product-single-303','product-single-304','product-single-305','product-single-306','product-single-307','product-annual-301','product-annual-302','product-longstay-301'],
      productsSompo: [],
      productsMondial: ['product-single-6400','product-single-6403','product-single-6535','product-single-6418','product-single-6419','product-single-6421','product-single-6440','product-single-6499','product-single-6520','product-single-6583'],
      productsBattleface: ['product-single-401','product-single-402','product-single-403','product-single-404','product-single-405','product-single-406','product-single-407','product-single-408','product-single-409','product-single-410']
    }
  },
  methods: {
    async getCoverage(products,pos) {
      const urls= {
        c: 'https://api-quote.safetravel.today/v2/app/product_coverages',
        b: 'https://api-quote.safetravel.today/v2/app/product_coveragesb'
      };
      const num = products[0].replace(/^\D+/g, '');
      const url = (num[0] == 4) ? urls.b : urls.c
      const data = {
        token:"1dbaa403b94ccdd1991c3afd511858a5",
        products
      }
      await fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      .then(response=>response.json())
      .then(res=> {
        if (res) {
          this.productsArr.push(res)
          if (pos==='last') {
            this.loading=false;
          }
        }
      })
    },
  },
  async created(){
    await this.getCoverage(this.productsCollinson,null)
    // await this.getCoverage(this.productsSompo,null)
    await this.getCoverage(this.productsMondial,null)
    await this.getCoverage(this.productsBattleface,'last')
  },
}
</script>

<style lang="scss">
  .listgroupsdesc {
    box-shadow: 0px 0px 6px 1px var(--accent3-color);
  }
  .flexDiv {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  h1,h2,h3,h4,h5,h6 {
    color:var(--accent1-color);
  }
  p,span,li {
    color:var(--foreground-color);
  }
</style>
